<template>
    <div>
        <Quote/>
        <GoBack next="Modulni tahrirlash"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
                <form class="ABC" @submit.prevent="changeModule">
                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Kurs turini tanlang</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="editModule.type"
                            required
                        >
                            <option
                                v-for="courses of getCourseTypes"
                                :key="courses.id"
                                :label="courses.name"
                                :value="'/api/course_types/' + courses.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">O'qituvchini tanlang</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="editModule.teacher"
                        >
                            <option
                                v-for="teacher in getTeachersList"
                                :key="teacher.id"
                                :label="teacher.givenName + ' ' + teacher.familyName"
                                :value="'/api/users/' + teacher.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Kursni tanlang</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="editModule.module"
                        >
                            <option
                                v-for="course in getModules"
                                :key="course.id"
                                :label="course.name"
                                :value="'/api/modules/' + course.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputId4" class="form-label">Modul nomini o'zgartiring</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputId4"
                            v-model="editModule.name"
                            required
                        >
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputId4" class="form-label">Modul narxini o'zgartiring</label>
                        <b-form-input
                            v-model="editModule.price"
                            :formatter="formatNumber"
                        >

                        </b-form-input>

                    </div>

                    <div class="mb-3">
                        <label for="exampleInputId3" class="form-label">Qaysi oydan boshlanadi?</label>
                        <b-form-select
                            class="form-control"
                            id="exampleInputId3"
                            v-model="editModule.startMonth"
                            required
                        >
                            <option
                                v-for="month of getMonths.models"
                                :key="month.id"
                                :value="'/api/months/' + month.id"
                            > {{ month.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputId4" class="form-label">Telegram guruhi linkini o'zgartiring</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputId5"
                            v-model="editModule.link"
                        >
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputId2" class="form-label">Joylar soni</label>
                        <input
                            type="number"
                            class="form-control"
                            id="exampleInputId2"
                            v-model="editModule.countOfStudents"
                            required
                        >
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="row mb-3 mt-3 col-12 col-md-5 col-lg-4 col-xl-3">
                            <button type="submit" class="btn btn-primary">O'zgartirish</button>
                        </div>
                    </div>
                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Modul muvaffaqiyatli o'zgartirildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";
import accessMixin from "@/mixin/accessMixin";

export default {
    name: "Change-Module",
    mixins: [accessMixin],
    components: {GoBack, Quote},
    data() {
        return {
            editModule: {
                id: null,
                name: '',
                type: '',
                startMonth: '',
                price: '',
                module: '',
                link: '',
                countOfStudents: 0,
                teacher: null,
            },
            show: true,
            currentPage: 1,
        }
    },
    computed: {
        ...mapGetters([
            'getCourseTypes',
            'getMonths',
            'getTeachersList',
            'getModules',
            'getCourse'
        ]),
    },
    methods: {
        ...mapActions([
            'fetchCourseTypes',
            'fetchMonth',
            'fetchTeachers',
            'fetchModules',
            'fetchCourse',
            'putCourse'
        ]),
        changeModule() {
            let price = this.editModule.price + '00'
            price.replace(/\s/g, "");
            let data = {
                id: this.$route.params.id,
                name: this.editModule.name,
                type: this.editModule.type,
                startMonth: this.editModule.startMonth,
                price: parseInt(price.replace(/\s/g, "")),
                module: this.editModule.module,
                link: this.editModule.link,
                countOfStudents: parseInt(this.editModule.countOfStudents),
                teacher: this.editModule.teacher
            }
            this.putCourse(data)
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                })
                .catch(() => {
                    console.log('addModule da xato')
                })
        },
        formatNumber(n) {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/cabinet')
        }

    },
    mounted() {
        this.show = true
        this.fetchCourse(this.$route.params.id)
            .then(() => {
                this.editModule.id = this.getCourse.id
                this.editModule.name = this.getCourse.name
                this.editModule.type = this.getCourse.type['@id']
                this.editModule.startMonth = this.getCourse.startMonth['@id']
                let price = this.getCourse.price.toString()
                this.editModule.price = price.slice(0, -2)
                this.editModule.module = this.getCourse.module['@id']
                this.editModule.link = this.getCourse.link
                this.editModule.countOfStudents = this.getCourse.countOfStudents
                this.editModule.teacher = this.getCourse.teacher['@id']
            })
        this.fetchCourseTypes()
        this.fetchModules()
        this.fetchMonth()
        this.fetchTeachers(1)
        this.show = false
    }
}
</script>

<style scoped>

* {
    padding: 0;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;


}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>
